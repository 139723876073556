@font-face {
  font-family: "NetflixSansRegular";
  src: local("NetflixSansRegular"),
    url("../fonts/NetflixSans_W_Rg.woff2") format("woff2");
}

@font-face {
  font-family: "NetflixSansLight";
  src: local("NetflixSansLight"),
    url("../fonts/NetflixSans_W_Lt.woff2") format("woff2");
}

@font-face {
  font-family: "NetflixSansMedium";
  src: local("NetflixSansMedium"),
    url("../fonts/NetflixSans_W_Md.woff2") format("woff2");
}

@font-face {
  font-family: "NetflixSansBold";
  src: local("NetflixSansBold"),
    url("../fonts/NetflixSans_W_Bd.woff2") format("woff2");
}
